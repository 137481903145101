<template>
  <div>
    <b-row class="card">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-pen"></i> Filtros
        </h5>
      </div>

      <div class="w-100 p-3">
        <b-row>
          <b-col lg="2">
            <b-form-group label="Cód. Produto">
              <b-form-input v-model="filters.codigo"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="4">
            <b-form-group label="Inversor">
              <b-form-input v-model="filters.inverter"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Placa">
              <b-form-input v-model="filters.panel"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col lg="3">
            <b-form-group label="Potência kit">
              <b-form-input v-model="filters.power"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="3">
            <b-form-group label="Estrutura">
              <b-form-select :options="structureOptions" v-model="filters.structure"/>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="align-btn">
            <b-button variant="info" class="mr-2" @click="getList(true)">Pesquisar</b-button>
            <b-button variant="danger">Limpar</b-button>
          </b-col>
        </b-row>
      </div>
    </b-row>
    <b-row class="card mt-5">
      <div class="w-100">
        <h5 class="title-card">
          <i class="fas fa-solar-panel"></i> Produtos e Kits
        </h5>
      </div>
      <div class="w-100 p-3">
        <b-row>
          <b-col lg="4">
            <b-form-group label="(%) Sobre as vendas">
              <div class="d-flex">
                <b-form-input class="w-50" v-model="config.percentage_sale" :disabled="!edit_sale"></b-form-input>
                <b-button class="ml-2"
                v-if="!edit_sale"
                @click="() => edit_sale = !edit_sale"
                variant="warning">
                  <i class="fas fa-pen"></i> Alterar
                </b-button>
                <b-button class="ml-2"
                v-else
                @click="save"
                variant="primary">
                  <i class="fas fa-save"></i> Salvar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <!-- <b-col lg="4">
            <b-form-group label="(%) Sobre os produtos">
              <div class="d-flex">
                <b-form-input class="w-50" v-model="config.percentage_product" :disabled="!edit_product"></b-form-input>
                <b-button class="ml-2"
                v-if="!edit_product"
                @click="() => edit_product = !edit_product"
                variant="warning">
                  <i class="fas fa-pen"></i> Alterar
                </b-button>
                <b-button class="ml-2"
                v-else
                @click="save"
                variant="primary">
                  <i class="fas fa-save"></i> Salvar
                </b-button>
              </div>
            </b-form-group>
          </b-col> -->
          <b-col lg="4">
            <b-form-group label="(%) Financiamento">
              <div class="d-flex">
                <b-form-input class="w-50" v-model="config.percentage_financing" :disabled="!edit_financing"></b-form-input>
                <b-button class="ml-2"
                v-if="!edit_financing"
                @click="() => edit_financing = !edit_financing"
                variant="warning">
                  <i class="fas fa-pen"></i> Alterar
                </b-button>
                <b-button class="ml-2"
                v-else
                @click="save"
                variant="primary">
                  <i class="fas fa-save"></i> Salvar
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
       </div>
      <div class="w-100 p-3">
        <b-table
          id="tb-products"
          responsive
          bordered
          :items="products"
          :fields="columsTableProducts">
          <template #table-busy>
            <div class="text-center text-success my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Carregando...</strong>
            </div>
          </template>
          <template v-slot:cell(updated_at)="data">
            {{ data.item.updated_at | moment('DD/MM/YY') }}
          </template>
           <template v-slot:cell(actions)="">
            <b-button
            v-b-tooltip.hover
            title="Editar Kit"
            size="sm"
            class="mr-1"
            variant="warning">
              <i class="fas fa-pen"></i>
            </b-button>
           </template>
        </b-table>
      </div>
    </b-row>
  </div>
</template>
<script>
import ProductService from '@/Services/ProductService';
import ConfigAdminService from '@/Services/ConfigAdminService';
import MessagesMixin from '@/mixins/messages-mixin';

export default {
  data() {
    return {
      columsTableProducts: ProductService.getFieldsTableProducts(),
      products: [],
      edit_product: false,
      edit_sale: false,
      edit_financing: false,
      structureOptions: [
        {
          text: 'Selecione',
          value: '',
        },
        {
          text: 'Colonial',
          value: 'colonial',
        },
        {
          text: 'Paraf Madeira',
          value: 'madeira',
        },
        {
          text: 'Paraf Metal',
          value: 'metal',
        },
        {
          text: 'Trapezoidal',
          value: 'trapezoidal',
        },
        {
          text: 'Solo',
          value: 'solo',
        },
      ],
      filters: {
        codigo: '',
        inverter: '',
        panel: '',
        power: '',
        structure: '',
      },
      config: {
        id: '',
        percentage_sale: '',
        percentage_product: '',
        percentage_financing: '',
      },
    };
  },
  mixins: [MessagesMixin],
  methods: {
    async getList(hasFilter = false) {
      const filters = { ...this.filters };
      this.$store.dispatch('setLoad', true);
      const { data } = hasFilter ? await ProductService.getList(filters) : await ProductService.getList();

      this.config = data.config;

      this.products = data.products.data.map((item) => ({
        codigo: item.codigo,
        description: item.descricao,
        price: this.$options.filters.currency(item.preco),
        price_sub: this.$options.filters.currency(item.preco + item.preco * (data.config.percentage_sale / 100)),
        updated_at: item.updated_at,
      }));

      this.$store.dispatch('setLoad', false);
    },
    clearFilters() {
      this.filters = {
        codigo: '',
        inverter: '',
        panel: '',
        power: '',
        structure: '',
      };

      this.getList();
    },
    async save() {
      try {
        const payload = {
          id: this.config.id,
          percentage_product: parseFloat(this.config.percentage_product) / 100,
          percentage_sale: parseFloat(this.config.percentage_sale) / 100,
          percentage_financing: parseFloat(this.config.percentage_financing) / 100,
        };
        await ConfigAdminService.edit(payload);
        this.messageSuccess('Configuração salva com sucesso');
        this.getList();
        this.desactiveAll();
      } catch (error) {
        console.log(error);
      }
    },
    desactiveAll() {
      this.edit_product = false;
      this.edit_sale = false;
      this.edit_financing = false;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="css">
#tb-products td {
  font-size: 14px !important;
}
</style>
