/* eslint-disable class-methods-use-this */
import Service from './Service';

class ProductService extends Service {
  constructor() {
    super('products');
  }

  getFieldsTableProducts() {
    return [
      {
        key: 'codigo',
        label: 'Código',
        tdClass: 'text-nowrap',
        thClass: 'text-nowrap',
      },
      {
        key: 'description',
        label: 'Descrição',
      },
      {
        key: 'price',
        label: 'Preço',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center',
      },
      {
        key: 'price_sub',
        label: 'Preço Aldo',
        tdClass: 'text-center text-nowrap',
        thClass: 'text-center text-nowrap',
      },
      {
        key: 'updated_at',
        label: 'Atualizado',
        tdClass: 'text-center',
        thClass: 'text-center',
      },
    ];
  }
}

const productService = new ProductService();
export default productService;
