/* eslint-disable class-methods-use-this */
import Service from './Service';

class ConfigAdminService extends Service {
  constructor() {
    super('config-admin');
  }
}

const configAdminService = new ConfigAdminService();
export default configAdminService;
